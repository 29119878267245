// istanbul ignore file
import * as React from 'react';
import { VideoTrack, VideoTrackState } from '../../lib/multi-video/video-track';
import { VideoPlayerBase } from '../VideoPlayer/VideoPlayerBase';
import { Box, Fade } from '@mui/material';
import { MultiVideoController } from '../../lib/multi-video/multi-video';
import { MultiBlock } from '../MultiBlock';
import { VehicleTimelineVideoControls } from '../pages/VehicleTimelineShow/VehicleTimelineVideoControls';
import { useMultiVideoState } from './VideoPlayerMulti';
import { useIsHovered } from '../../hooks/use-is-hovered';
import { appStyled } from '../../theme';

export function VideoPlayerTrack({
    track,
    controller,
}: {
    track: VideoTrack;
    controller: MultiVideoController;
}): React.ReactElement {
    const vidContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [state, _setState] = React.useState<VideoTrackState>();
    React.useEffect(() => {
        track.on('stateChanged', _setState);
        return () => void track.off('stateChanged', _setState);
    }, [track]);

    const { playbackState } = useMultiVideoState(controller);

    const loading = state?.loading;
    const noVideo = state?.videoStatus === 'no-video';

    const overlayNoise = noVideo && playbackState === 'playing';
    const overlayText = noVideo
        ? 'no video is uploaded at this time'
        : loading && playbackState === 'playing'
          ? 'video will play when loaded'
          : undefined;

    return (
        <Box sx={{ width: '100%', maxHeight: 'calc(100vh - 280px)', aspectRatio: '16 / 9' }}>
            <Box
                sx={{
                    backgroundColor: 'grey',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    maxHeight: '100%',
                    maxWidth: '100%',
                }}
            >
                <VideoPlayerBase
                    hideControlsUnlessHover
                    videoRef={track.setVideoElement}
                    containerRef={vidContainerRef}
                    overlayLoading={loading}
                    overlayNoise={overlayNoise}
                    overlayCentered={overlayText}
                    overlayDebug={<pre>{JSON.stringify(state, null, 2)}</pre>}
                />
            </Box>
        </Box>
    );
}

type VideoPlayerMultiTrackProps = {
    controller: MultiVideoController;
    loading: boolean;
};

const VideoContainer = appStyled('div')({
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: 'grey',
    overflow: 'hidden',
});

export function VideoPlayerMultiTrack({ controller, loading }: VideoPlayerMultiTrackProps): React.ReactElement {
    const { tracks } = useMultiVideoState(controller);
    const [isHovered, hoveredProps] = useIsHovered();

    const showControls = isHovered;

    const renderedTracks = React.useMemo(
        () => tracks.map((t, idx) => <VideoPlayerTrack key={idx} track={t} controller={controller} />),
        [tracks],
    );

    return (
        <Box height={'600px'} maxHeight={'600px'} {...hoveredProps}>
            <VideoContainer>
                {loading ? (
                    <Box height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        {'loading...'}
                    </Box>
                ) : renderedTracks.length ? (
                    <MultiBlock items={renderedTracks} />
                ) : (
                    <Box height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        {'No video is ready in this 24 hour span'}
                    </Box>
                )}
                <Fade in={showControls} style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }}>
                    <div>
                        <VehicleTimelineVideoControls controller={controller} />
                    </div>
                </Fade>
            </VideoContainer>
        </Box>
    );
}
