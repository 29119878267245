import { ElementOfArray } from '../types/util-types';

export const EVENT_TYPE_DISTRACTION = {
    name: 'Distraction',
    id: 'Distraction',
    icon: 'fa-exclamation-triangle',
    color: '#ff8907',
} as const;

export const EVENT_TYPE_SEVERE_FATIGUE = {
    name: 'Severe fatigue',
    id: 'Eye closure',
    icon: 'fa-low-vision',
    color: '#663399',
} as const;

export const EVENT_TYPE_EARLY_FATIGUE = {
    name: 'Early fatigue',
    id: 'Eye closure early warning',
    icon: 'fa-duotone fa-face-hand-yawn',
    color: '#663399',
} as const;

export const EVENT_TYPE_COLLISION = {
    name: 'Collision',
    id: 'Collision',
    icon: 'fa-solid fa-car-burst',
    color: '#FF3333',
} as const;

export const EVENT_TYPE_HARSH_MANEUVER = {
    name: 'Harsh maneuver',
    id: 'Harsh maneuver',
    icon: 'fa-car-tilt',
} as const;

export const EVENT_TYPE_REESTABLISEHD_NETWORK = {
    name: 'Connected',
    id: 'gen_found_ping',
    icon: 'fa-signal',
} as const;

export const EVENT_TYPE_LOST_NETWORK = {
    name: 'Disconnected',
    id: 'gen_lost_ping',
    icon: 'fa-signal-slash',
} as const;

export const EVENT_TYPE_IPHONE_UNPLUGGED = {
    name: 'iPhone unplugged',
    id: 'gen_iphone_unplugged',
    icon: 'fa-plug',
} as const;

export const EVENT_TYPE_IPHONE_CHARGING = {
    name: 'iPhone charging',
    id: 'gen_iphone_charging',
    icon: 'fa-bolt',
} as const;

export const EVENT_TYPE_TAMPERING_DETECTED = {
    name: 'Tampering detected',
    id: 'gen_tamper_v2',
    icon: 'fa-paw',
} as const;

export const EVENT_TYPE_SENSOR_COVERED = {
    name: 'Sensor covered',
    id: 'gen_sensor_covered_v2',
    icon: 'fa-hand-paper',
} as const;

export const EVENT_TYPE_BATTERY_LEVEL_DROP = {
    name: 'Battery level drop',
    id: 'gen_iphone_battery_level_drop',
    icon: 'fa-battery-quarter',
} as const;

export const EVENT_TYPES = [
    EVENT_TYPE_DISTRACTION,
    EVENT_TYPE_SEVERE_FATIGUE,
    EVENT_TYPE_EARLY_FATIGUE,
    EVENT_TYPE_HARSH_MANEUVER,
    EVENT_TYPE_COLLISION,
    EVENT_TYPE_REESTABLISEHD_NETWORK,
    EVENT_TYPE_LOST_NETWORK,
    EVENT_TYPE_IPHONE_UNPLUGGED,
    EVENT_TYPE_IPHONE_CHARGING,
    EVENT_TYPE_TAMPERING_DETECTED,
    EVENT_TYPE_SENSOR_COVERED,
    EVENT_TYPE_BATTERY_LEVEL_DROP,
] as const;

export const EVENT_TYPE_MAP_NAME = EVENT_TYPES.reduce(
    (obj, e) => ({
        ...obj,
        [e.name]: e,
    }),
    {},
) as EventTypeMapName;

export const EVENT_TYPE_MAP_ID = EVENT_TYPES.reduce(
    (obj, e) => ({
        ...obj,
        [e.id]: e,
    }),
    {},
) as EventTypeMapId;

export type EventConfig = ElementOfArray<typeof EVENT_TYPES>;
export type EventTypeName = EventConfig['name'];
export type EventTypeId = EventConfig['id'];

type EventTypeMapName = {
    [K in EventConfig['name']]: Extract<EventConfig, { name: K }>;
};
type EventTypeMapId = {
    [K in EventConfig['id']]: Extract<EventConfig, { id: K }>;
};
