import * as React from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { VehicleDevice } from '../../types/stein';
import { getDrivingState } from './vehicle_utils';
import { capitalize } from '@mui/material';
import { useAppTheme } from '../../theme';

type VehicleLastSeenProps = {
    vehicle: VehicleDevice;
};

export function VehicleLastSeen({ vehicle }: VehicleLastSeenProps): React.ReactElement {
    const state = getDrivingState(vehicle);
    const theme = useAppTheme();

    if (state === 'offline') {
        return vehicle.lastSeenAt ? (
            <Box
                sx={{ textWrap: 'pretty', textAlign: 'left' }}
            >{`Seen ${formatDistanceToNow(new Date(vehicle.lastSeenAt), { addSuffix: true })}`}</Box>
        ) : (
            <Tooltip title={'This vehicle has not posted an event recently.'}>
                <span style={{ color: theme.palette.warning.main }}> {'Not Found'}</span>
            </Tooltip>
        );
    }

    const text = capitalize(state);
    const color = state === 'driving' ? 'success' : 'primary';

    return <Chip sx={{ justifyContent: 'flex-start' }} size="small" color={color} label={text} />;
}
