// istanbul ignore file
import * as React from 'react';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import LoadingButton from '@mui/lab/LoadingButton';
import { VehicleDeviceSlug } from '../../../types/stein';
import { toISO8601 } from '../../../utils/datetime-utils';
import { useAppSelector } from '../../../hooks/hooks';
import { selectNotificationById } from '../../../store/selectors';
import { AppNotificationId } from '../../Notifications/notificationTypes';
import { AppIconDownload } from '../../AppIcons';
import { TooltipOptional } from '../../material/TooltipOptional';

export function ExportTimelineVideoButton({
    vehicleDeviceSlug,
    date,
}: {
    vehicleDeviceSlug: VehicleDeviceSlug;
    date: Date;
}): React.ReactElement {
    const { useLazyStartArchiveQuery } = useFrame(SteinInternalApiClientToken);
    const [_startArchive, { data: archive }] = useLazyStartArchiveQuery();

    function startArchive(): void {
        _startArchive({
            type: 'vehicle_device_video',
            id: vehicleDeviceSlug,
            startTime: toISO8601(date),
        });
    }

    const notification = useAppSelector((s) => selectNotificationById(s, archive?.id as AppNotificationId | undefined));
    const isPending = Boolean(notification?.type === 'archive' && notification.status === 'pending');

    return (
        <TooltipOptional
            title={'Download Video Archive'}
            description={
                'Export an archive of all video files that are uploaded and ready to play in the current timeline. '
            }
        >
            <LoadingButton onClick={startArchive} size={'small'} variant={'contained'} loading={isPending}>
                <AppIconDownload />
            </LoadingButton>
        </TooltipOptional>
    );
}
