import { Stack } from '@mui/system';
import * as React from 'react';

import { VehicleDevice } from '../../../types/stein';
import { define } from '../../../utils/undefined-utils';
import { GeofenceChip, RegionChip } from '../../AppChip';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { BatteryStatusIndicator } from '../../Vehicle/BatteryStatusIndicator';
import { IOSUpdateIndicator } from '../../Vehicle/IOSUpdateIndicator';
import { ObservantUpdateIndicator } from '../../Vehicle/ObservantUpdateIndicator';
import { isVehicleOnline } from '../../Vehicle/vehicle_utils';
import { AppIconMicMute, AppIconMicUnmute } from '../../AppIcons';
import { Box } from '@mui/material';
import { useAppTheme } from '../../../theme';
import { TooltipOptional } from '../../material/TooltipOptional';
import { VehicleLastSeen } from '../../Vehicle/VehicleLastSeen';

type VehicleDeviceStatusBoxProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceStatusBox({ vehicle }: VehicleDeviceStatusBoxProps): React.ReactElement {
    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Device Status" />;
    }

    const isOnline = isVehicleOnline(vehicle);
    const theme = useAppTheme();

    return (
        <CalloutBox name="Status">
            <Stack spacing={1}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'Activity: '}</strong>
                    <VehicleLastSeen vehicle={vehicle} />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'iOS version: '}</strong>
                    <div>{vehicle.operatingSystemVersion}</div>
                    <IOSUpdateIndicator vehicle={vehicle} />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'App version: '}</strong>
                    <div>
                        {vehicle.lastBundleVersion
                            ? /*istanbul ignore next*/ vehicle.lastBundleVersion
                            : vehicle.lastBundleShortVersion}
                    </div>
                    <ObservantUpdateIndicator vehicle={vehicle} />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'Microphone: '}</strong>
                    <div>
                        <TooltipOptional
                            title={
                                vehicle.microphonePermissionGranted
                                    ? 'Permissions Granted'
                                    : vehicle.microphonePermissionDenied
                                      ? 'Permissions Denied'
                                      : 'Permissions Not Requested'
                            }
                            description={
                                vehicle.microphonePermissionGranted
                                    ? 'This vehicle will record audio'
                                    : 'This device will not record audio'
                            }
                        >
                            <Box
                                display="flex"
                                justifyContent={'center'}
                                sx={{
                                    color: vehicle.microphonePermissionGranted
                                        ? theme.palette.success.main
                                        : theme.palette.error.main,
                                }}
                            >
                                {vehicle.microphonePermissionGranted ? <AppIconMicMute /> : <AppIconMicUnmute />}
                            </Box>
                        </TooltipOptional>
                    </div>
                </Stack>

                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'Battery: '}</strong>
                    <BatteryStatusIndicator
                        batteryState={define(vehicle.lastBatteryState)}
                        batteryLevel={define(vehicle.lastBatteryLevel)}
                        isOnline={isOnline}
                    />
                </Stack>

                {
                    /*istanbul ignore next*/ vehicle.geofenceIds ? (
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <strong>{'Facilities: '}</strong>
                            {vehicle.geofenceIds.map((gId) => (
                                <GeofenceChip geofenceId={gId} size={'small'} key={gId} />
                            ))}
                        </Stack>
                    ) : null
                }

                {
                    /*istanbul ignore next*/ vehicle.regionId ? (
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <strong>{'Region: '}</strong>
                            <RegionChip regionId={vehicle.regionId} size={'small'} />
                        </Stack>
                    ) : null
                }
            </Stack>
        </CalloutBox>
    );
}
