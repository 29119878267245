import * as React from 'react';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import LoadingButton from '@mui/lab/LoadingButton';
import { VehicleDeviceSlug } from '../../../types/stein';
import { addMinutes, differenceInSeconds } from 'date-fns';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { formatDateShort, getTimezoneStr, toISO8601, toTimestampMs } from '../../../utils/datetime-utils';
import { TimelineRow, TimelineSpan } from './VehicleTimelineScrubber/TimelineComponents/TimelineComponents';

export type VideoUploadRequestDraft = {
    startDate: Date;
    endDate: Date;
};

type VideoUploadRequestDraftContext = {
    draft: VideoUploadRequestDraft | null;
    setDraft: React.Dispatch<React.SetStateAction<VideoUploadRequestDraft | null>>;
};

const videoUploadRequestDraftContext = React.createContext<VideoUploadRequestDraftContext>(
    // @ts-expect-error this can't be null, but we set it first in TimelineProvider
    null,
);

export function VideoUploadRequestDraftProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [draft, setDraft] = React.useState<VideoUploadRequestDraft | null>(null);

    return (
        <videoUploadRequestDraftContext.Provider
            value={{
                draft,
                setDraft,
            }}
        >
            {children}
        </videoUploadRequestDraftContext.Provider>
    );
}

export function useVideoUploadRequestDraftContext(): VideoUploadRequestDraftContext {
    return React.useContext(videoUploadRequestDraftContext);
}

export function VideoUploadRequestDraftRow(): React.ReactElement | null {
    const { draft } = useVideoUploadRequestDraftContext();
    if (!draft) {
        return null;
    }

    const startTime = toTimestampMs(draft.startDate);
    const durationSeconds = differenceInSeconds(draft.endDate, draft.startDate);

    return (
        <TimelineRow sx={{ marginTop: 0, height: 0 }}>
            <TimelineSpan
                startTime={startTime}
                durationSeconds={durationSeconds}
                sx={{
                    height: '45px',
                    backgroundColor: 'rgba(255,255,10, 0.4)',
                    borderRadius: 0,
                    pointerEvents: 'none',
                }}
            />
        </TimelineRow>
    );
}

export function EditVideoUploadRequestDraft({
    vehicleDeviceSlug,
}: {
    vehicleDeviceSlug: VehicleDeviceSlug;
}): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const { draft, setDraft } = useVideoUploadRequestDraftContext();
    const show = Boolean(draft);

    const { useCreateVideoUploadRequestMutation } = useFrame(SteinInternalApiClientToken);
    const [createVideoUploadRequest, { isLoading }] = useCreateVideoUploadRequestMutation();

    async function handleCreate(): Promise<void> {
        // istanbul ignore else
        if (draft) {
            await createVideoUploadRequest({
                projectSlug,
                videoUploadRequest: {
                    vehicleDeviceSlug,
                    startTime: toISO8601(draft.startDate),
                    endTime: toISO8601(draft.endDate),
                },
            }).unwrap();
        }

        setDraft(null);
    }

    function onHide(): void {
        setDraft(null);
    }

    return (
        <Collapse in={show}>
            <Stack
                spacing={2}
                padding={1}
                sx={{
                    backgroundColor: 'white',
                    borderTopRightRadius: '5px',
                    borderTopLeftRadius: '5px',
                }}
            >
                <Typography
                    variant={'body1'}
                >{`Click and drag on the timeline to select a time span to request video:`}</Typography>
                <Typography
                    variant={'body1'}
                >{`${draft && formatDateShort(draft.startDate)} - ${draft && formatDateShort(draft.endDate)} (${getTimezoneStr()})`}</Typography>

                <Stack spacing={1} direction={'row'}>
                    <LoadingButton
                        loading={isLoading}
                        size={'small'}
                        variant={'contained'}
                        onClick={handleCreate}
                        sx={{ flex: 1 }}
                    >
                        Submit Request
                    </LoadingButton>
                    <Button size={'small'} onClick={onHide} color={'error'} aria-label="Cancel">
                        Cancel
                    </Button>
                </Stack>
            </Stack>
        </Collapse>
    );
}

export function CreateVideoUploadRequest({ date }: { date: Date }): React.ReactElement {
    const { draft, setDraft } = useVideoUploadRequestDraftContext();
    const show = Boolean(draft);

    function onHide(): void {
        setDraft(null);
    }

    const startDate = draft?.startDate || date;
    const endDate = draft?.endDate || addMinutes(date, 30);

    function onClick(): void {
        show
            ? onHide()
            : setDraft({
                  startDate,
                  endDate,
              });
    }

    return (
        <Button onClick={onClick} size={'small'} variant={'contained'} color={show ? 'error' : 'primary'}>
            {show ? 'Cancel fetch' : 'Fetch video'}
        </Button>
    );
}
