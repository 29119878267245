import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FontAwesomeClassName } from '../../components/AppIcons';
import { FilterPreset } from '../../types/stein';
import { EVENT_TYPES } from '../../constants/event-type-constants';
import { EVENT_ANALYSIS } from '../../constants/event-analysis-constants';
import { EVENT_INFRACTIONS } from '../../constants/event-infraction-constants';
import { ElementOfArray } from '../../types/util-types';
import { StrColorHash } from '../../utils/color-utils';
import { setRailsContext } from './shared-actions';
import { configItemFind } from '../../hooks/use-config-items';

export type FilterValueId = string;
export type FilterValueType = {
    readonly name: string;
    readonly id: FilterValueId;
    readonly icon?: FontAwesomeClassName;
    readonly color?: 'error' | 'warning' | 'success' | 'primary' | StrColorHash;
};

export type FilterGroup = { group: string };

export type FilterConfig<T extends Record<string, (FilterValueType | FilterGroup)[]>> = {
    presets: Array<FilterPreset>;
    activePresetIndex: number;
    options: T;
};

export type FilterStore = {
    driverEvents: FilterConfig<{
        eventNames: ElementOfArray<typeof EVENT_TYPES>[];
        infractions: ElementOfArray<typeof EVENT_INFRACTIONS>[];
        analysis: ElementOfArray<typeof EVENT_ANALYSIS>[];
    }>;
    vehicleDevices: FilterConfig<{
        status: (FilterValueType | FilterGroup)[];
        godMode: FilterValueType[];
    }>;
};

export type FilterContextName = keyof FilterStore;

const initialState: FilterStore = {
    driverEvents: {
        activePresetIndex: 0,
        presets: [
            {
                name: 'Driver Events',
                filters: {
                    eventNames: ['Distraction', 'Eye closure', 'Eye closure early warning'],
                },
            },
            {
                name: 'Maintenance Events',
                filters: {
                    eventNames: [
                        'gen_found_ping',
                        'gen_lost_ping',
                        'gen_iphone_unplugged',
                        'gen_iphone_charging',
                        'gen_tamper_v2',
                        'gen_sensor_covered_v2',
                        'gen_iphone_battery_level_drop',
                    ],
                },
            },
        ],
        options: {
            eventNames: EVENT_TYPES.slice(),
            infractions: EVENT_INFRACTIONS.slice(),
            analysis: EVENT_ANALYSIS.slice(),
        },
    },
    vehicleDevices: {
        activePresetIndex: 0,
        options: {
            godMode: [
                {
                    id: 'ios_update',
                    name: 'iOS Needs Update',
                },
                {
                    id: 'app_update',
                    name: 'App Needs Update',
                },
            ],
            status: [
                {
                    group: 'Alerts',
                },
                {
                    id: 'muted',
                    name: 'Muted',
                    color: 'error',
                    icon: 'fa-volume-mute',
                },
                {
                    id: 'unmuted',
                    name: 'Unmuted',
                    color: 'success',
                    icon: 'fa-volume',
                },
                {
                    group: 'Battery',
                },
                {
                    id: 'low',
                    name: 'Battery Low',
                    color: 'error',
                    icon: 'fa-battery-empty',
                },
                {
                    id: 'unplugged',
                    name: 'Not Charging',
                    color: 'warning',
                    icon: 'fa-battery-half',
                },
                {
                    group: 'Wifi',
                },
                {
                    id: 'disconnected',
                    name: 'Disconnected',
                    color: 'warning',
                    icon: 'fa-wifi-slash',
                },
                {
                    group: 'Camera',
                },
                {
                    id: 'cameraDisabled',
                    name: 'Camera Disabled',
                    color: 'error',
                    icon: 'fa-camera-home',
                },
                {
                    group: 'Microphone',
                },
                {
                    id: 'mic_disabled',
                    name: 'Microphone Disabled',
                    color: 'error',
                    icon: 'fa-microphone-slash',
                },
                {
                    id: 'mic_enabled',
                    name: 'Microphone Enabled',
                    color: 'success',
                    icon: 'fa-microphone',
                },
            ],
        },
        presets: [
            {
                name: 'All Vehicles',
                filters: {},
            },
            {
                name: 'Has Errors',
                filters: {
                    status: ['ios_update', 'app_update', 'muted', 'low', 'unplugged', 'disconnected'],
                },
            },
        ],
    },
};

type SetActivePresetPayload = {
    filterContextName: FilterContextName;
    presetIndex: number;
};

export const filterConfigSlice = createSlice({
    name: 'filterConfig',
    initialState,
    reducers: {
        setActivePreset(state, action: PayloadAction<SetActivePresetPayload>) {
            state[action.payload.filterContextName].activePresetIndex = action.payload.presetIndex;
        },
    },
    extraReducers: (builder) => {
        // TODO This should be set by project
        builder.addCase(setRailsContext, (s, a) => {
            const ci = configItemFind(a.payload.configItems, 'driver_event_filter_presets');
            if (ci) {
                s.driverEvents.presets = ci.jsonValue?.presets;
            }
        });
    },
});
