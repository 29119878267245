// istanbul ignore file
// TODO, this might be unit testable
import { alpha, Box, Tooltip } from '@mui/material';
import { addMinutes, addSeconds, differenceInSeconds, format, isBefore } from 'date-fns';
import * as React from 'react';
import { useAppNavigate } from '../../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../../hooks/use-active-project';
import { MultiVideoController } from '../../../../lib/multi-video/multi-video';
import { useAppTheme } from '../../../../theme';
import { DateTimestampMs, DriverEvent, DriverLog, VideoUploadRequest } from '../../../../types/stein';
import { parseISO8601, toTimestampMs } from '../../../../utils/datetime-utils';
import { urlDriverEventShow } from '../../../../utils/internal-url-utils';
import { AppIconLink } from '../../../AppIcons';
import { DriverEventIcon } from '../../../DriverEvent/DriverEventIcon';
import { InvisibleLink } from '../../../InvisibleLink';
import { IconButtonSimple } from '../../../material/IconButtonSimple';
import { TooltipContent } from '../../../TooltipContent';
import { useMultiVideoState } from '../../../VideoPlayerMulti/VideoPlayerMulti';
import { TimelinePoint, TimelineRow, TimelineSpan } from './TimelineComponents/TimelineComponents';
import { TimelineWrapper } from './TimelineComponents/TimelineWrapper';

import { TimelineProvider, useTimelineContext } from './TimelineComponents/TimelineProvider';
import { TimelineSources } from './TimelineSources';
import { TimeMarks } from './TimeMarks';
import { LocationEvent } from '../../../../types/stein-internal-api';
import { Permission } from '../../../Authorize/PermissionBlock';
import { VideoUploadRequestDraftRow, useVideoUploadRequestDraftContext } from '../VideoUploadRequest';

function Playhead({ playheadTime }: { playheadTime: DateTimestampMs | undefined }): React.ReactElement | null {
    const theme = useAppTheme();
    const color = theme.palette.grey[800];

    if (!playheadTime) {
        return null;
    }

    return (
        <TimelinePoint
            time={playheadTime}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                color: theme.palette.grey[400],
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    width: '2px',
                    height: '100%',
                    backgroundColor: theme.palette.grey[400],
                }}
            />
            <Box
                sx={{
                    fontSize: '12px',
                    backgroundColor: '',
                    backgroundImage: `linear-gradient(to right, ${alpha(color, 0)}, ${color} 15%, ${color} 85%, ${alpha(
                        color,
                        0,
                    )} 100%)`,
                    paddingX: 1,
                    paddingY: '3px',
                    width: '120px',
                    minWidth: '120px',
                    textAlign: 'center',
                    zIndex: 9999999999,
                    position: 'fixed',
                    bottom: '0%',
                }}
            >
                {format(playheadTime, 'h:mm:ss aa')}
            </Box>
        </TimelinePoint>
    );
}

export function Scrubber(): React.ReactElement {
    const { timeFromPosition, cursorTime, setCursorTime, commitCursorTime } = useTimelineContext();
    const { draft, setDraft } = useVideoUploadRequestDraftContext();
    const [pinDate, setPinDate] = React.useState<Date | null>(null);

    function onMouseMove(e: React.MouseEvent): void {
        // @ts-expect-error getBoundingClientRect exists
        const rect = e.target.getBoundingClientRect();
        const position = e.clientX - rect.left; //x position within the element.
        const time = timeFromPosition(position);
        setCursorTime(time);
        if (draft && pinDate) {
            const newTime = new Date(time);
            if (isBefore(newTime, pinDate)) {
                setDraft({
                    startDate: newTime,
                    endDate: pinDate,
                });
            } else {
                setDraft({
                    startDate: pinDate,
                    endDate: newTime,
                });
            }
        }
    }

    function onMouseDown(): void {
        if (draft && cursorTime) {
            const startDate = new Date(cursorTime);
            setPinDate(startDate);
            setDraft({
                startDate,
                endDate: addMinutes(startDate, 1),
            });
        } else {
            commitCursorTime();
        }
    }

    function onMouseUp(): void {
        setPinDate(null);
    }

    function onMouseLeave(): void {
        setPinDate(null);
        setCursorTime(undefined);
    }

    return (
        <>
            {cursorTime ? <Playhead playheadTime={cursorTime} /> : null}
            <div
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '75%',
                }}
            />
        </>
    );
}

function VideoUploadRequestItem({
    videoUploadRequest,
}: {
    videoUploadRequest: VideoUploadRequest;
}): React.ReactElement {
    const startDate = new Date(videoUploadRequest.startTime);
    const endDate = new Date(videoUploadRequest.endTime);

    const startTime = toTimestampMs(startDate);
    const durationSeconds = differenceInSeconds(endDate, startDate);

    return (
        <Tooltip
            title={
                <TooltipContent title="Fetch Video Request">
                    {'Any video recorded in this time range will be uploaded.'}
                </TooltipContent>
            }
            followCursor
        >
            <TimelineSpan
                startTime={startTime}
                durationSeconds={durationSeconds}
                sx={{
                    height: '15px',
                    backgroundColor: '#555',
                    borderRadius: 0,
                    cursor: 'auto',
                }}
            />
        </Tooltip>
    );
}

export function VehicleTimelineScrubber({
    controller,
    startTime,
    driverEvents,
    locationHistory,
    videoUploadRequests,
    zoom,
    driverLogs,
    roadDriverLogs,
}: {
    controller: MultiVideoController;
    startTime: DateTimestampMs;
    driverEvents: DriverEvent[];
    driverLogs: DriverLog[];
    roadDriverLogs: DriverLog[];
    videoUploadRequests: VideoUploadRequest[];
    locationHistory: LocationEvent[];
    zoom: number;
}): React.ReactElement {
    const { playbackTime } = useMultiVideoState(controller);
    const projectSlug = useActiveProjectSlug();
    const navigate = useAppNavigate();

    return (
        <TimelineProvider startTime={startTime} controller={controller} zoom={zoom}>
            <TimelineWrapper>
                <TimeMarks />
                {playbackTime ? <Playhead playheadTime={playbackTime} /> : null}
                <Scrubber />
                <TimelineRow sx={{ marginTop: 0, height: 0 }}>
                    {videoUploadRequests.map((vur) => (
                        <VideoUploadRequestItem videoUploadRequest={vur} key={vur.id} />
                    ))}
                </TimelineRow>

                <VideoUploadRequestDraftRow />
                <TimelineRow>
                    <TimelineSources driverLogs={driverLogs} prefix="Driver video" />
                </TimelineRow>
                <Permission debugOnly>
                    <TimelineRow>
                        <TimelineSources driverLogs={roadDriverLogs} prefix="Road video" />
                    </TimelineRow>
                </Permission>

                <TimelineRow>
                    {driverEvents.map((e) => {
                        const occurred = toTimestampMs(new Date(e.occurredAt || ''));
                        const seek = toTimestampMs(addSeconds(occurred, -5));
                        return (
                            <Tooltip
                                key={e.id}
                                title={
                                    <TooltipContent title={`${e.eventName} Event`}>
                                        <p>{'Click to seek to 5s before event.'}</p>
                                        <InvisibleLink to={urlDriverEventShow(projectSlug, e)}>
                                            <Box
                                                onClick={() => navigate(urlDriverEventShow(projectSlug, e))}
                                                display={'flex'}
                                                alignItems={'flex-end'}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid',
                                                }}
                                            >
                                                {'visit event page'} <AppIconLink size={'8px'} />
                                            </Box>
                                        </InvisibleLink>
                                    </TooltipContent>
                                }
                            >
                                <TimelinePoint time={occurred}>
                                    <IconButtonSimple onClick={() => controller.seek(seek)} sx={{ cursor: 'pointer' }}>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                borderRadius: '80% 0 55% 50% / 55% 0 80% 50%',
                                                backgroundColor: 'grey',
                                                transform: 'rotate(-45deg)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    transform: 'rotate(45deg)',
                                                    paddingTop: '2px',
                                                    fontSize: '10px',
                                                }}
                                            >
                                                <DriverEventIcon event={e} />
                                            </div>
                                        </div>
                                    </IconButtonSimple>
                                </TimelinePoint>
                            </Tooltip>
                        );
                    })}
                </TimelineRow>
                {locationHistory?.length >= 2 ? (
                    <Permission debugOnly>
                        <TimelineRow>
                            <Tooltip title={'Vehicle location is known at this time'}>
                                <TimelineSpan
                                    startTime={toTimestampMs(parseISO8601(locationHistory[0].occurredAt))}
                                    durationSeconds={differenceInSeconds(
                                        parseISO8601(locationHistory[locationHistory.length - 1].occurredAt),
                                        parseISO8601(locationHistory[0].occurredAt),
                                    )}
                                    sx={{
                                        height: '15px',
                                        backgroundColor: 'green',
                                        '&:hover': {
                                            backgroundColor: 'lightgreen',
                                        },
                                    }}
                                />
                            </Tooltip>
                        </TimelineRow>
                    </Permission>
                ) : null}
            </TimelineWrapper>
        </TimelineProvider>
    );
}
