import * as React from 'react';
import { useParams } from 'react-router';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useFrame } from '../../../lib/frame-react';
import { selectVehicleById } from '../../../store/selectors';
import { driverEventsSlice } from '../../../store/slices/driverEventsSlice';
import { parseInt } from 'lodash';
import { DriverEventInfractions } from './DriverEventInfractions';
import { DriverEventAnalysis } from './DriverEventAnalysis';
import { DriverEventNotes } from './DriverEventNotes';
import { DriverEventDuration } from './DriverEventDuration';

import { DriverEventSpeed } from './DriverEventSpeed';
import { DriverEventMap } from '../../DriverEvent/DriverEventMap';
import { DriverEventVideoList } from './DriverEventVideoList';
import { DriverEventExportArchive } from './DriverEventExportArchive';
import { DriverEventActivityLog } from './DriverEventActivityLog';
import { DriverEventId } from '../../../types/stein';
import { ToParams } from '../../../types/util-types';
import { PageLayoutColumns } from '../../PageLayouts';
import { DriverEventTypeHeader } from './DriverEventTypeHeader';
import { DriverEventAlert } from './DriverEventAlert';
import { ErrorPage } from '../ErrorPage';
import { LiveStream } from '../VehicleDeviceShow/VehicleDeviceContactBox';
import { Stack } from '@mui/system';
import { AppIconEvents, AppIconVehicle } from '../../AppIcons';
import { urlDriverEventList, urlVehicleDeviceShow } from '../../../utils/internal-url-utils';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { formatLocalizedDateTime, parseISO8601, toTimestampMs } from '../../../utils/datetime-utils';
import { Breadcrumbs } from '../../Breadcrumbs';
import { Permission } from '../../Authorize/PermissionBlock';
import { DriverEventGodMode } from './DriverEventGodMode';
import { Button } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { urlVehicleDeviceTimeline } from '../../../utils/internal-url-utils';
import { subSeconds } from 'date-fns';
import { filterUndefinedAndNull } from '../../../utils/filter-utils';

type DriverEventParams = {
    driverEventId: DriverEventId;
};

function useDriverEventParams(): DriverEventParams {
    const params = useParams<ToParams<DriverEventParams>>();
    return {
        ...params,
        driverEventId: parseInt(params.driverEventId) as DriverEventId,
    };
}

export function DriverEventShow(): React.ReactElement {
    const dispatch = useAppDispatch();
    const { driverEventId } = useDriverEventParams();
    const projectSlug = useActiveProjectSlug();

    const { useGetDriverEventQuery } = useFrame(SteinInternalApiClientToken);
    const { error, data, isLoading } = useGetDriverEventQuery({ id: driverEventId });

    const { primaryVideos, nearbyVideos, geofence, driverEvent: event } = data || {};
    const vehicleId = event ? event.vehicleDeviceId : null;
    const vehicle = useAppSelector((s) => selectVehicleById(s, vehicleId));

    React.useEffect(() => void dispatch(driverEventsSlice.actions.markAsSeen([driverEventId])), [driverEventId]);

    if (error && 'status' in error && error.status === 404) {
        return <ErrorPage type={'not-found'} content={'Event Not Found'} />;
    }

    return (
        <>
            <span data-testid={'page-driver-event-show'} />
            {event ? <span data-testid={'page-driver-event-show-loaded'} /> : null}

            {event?.occurredAt ? (
                <Breadcrumbs
                    items={[
                        vehicle
                            ? {
                                  Icon: AppIconVehicle,
                                  name: vehicle.name,
                                  url: urlVehicleDeviceShow(projectSlug, vehicle),
                              }
                            : /* istanbul ignore next */
                              null,
                        {
                            Icon: AppIconEvents,
                            name: 'Events',
                            url: urlDriverEventList(projectSlug),
                        },
                        {
                            name: formatLocalizedDateTime(new Date(event.occurredAt)),
                        },
                    ].filter(filterUndefinedAndNull)}
                />
            ) : null}

            <PageLayoutColumns
                left={
                    <>
                        <DriverEventTypeHeader event={event} />
                        <DriverEventAnalysis event={event} />
                        <DriverEventInfractions event={event} />

                        <DriverEventNotes event={event} />
                        <DriverEventDuration event={event} />
                        <Permission
                            auth={{ subject: 'DriverEvent', action: 'view_alert_details' }}
                            feature={'show_cabin_alert_status'}
                        >
                            <DriverEventAlert event={event} />
                        </Permission>
                        <DriverEventSpeed event={event} />
                        <Permission debugOnly>
                            <DriverEventGodMode event={event} />
                        </Permission>
                    </>
                }
                right={
                    <Stack spacing={1}>
                        {event && vehicle ? (
                            <>
                                <Permission auth={{ subject: 'VideoCall', action: 'live_stream' }}>
                                    <LiveStream vehicle={vehicle} />
                                </Permission>

                                <Permission auth={{ subject: 'VehicleDevice', action: 'view_timeline' }}>
                                    {/* @ts-expect-error wants href but link component wants "to" */}
                                    <Button
                                        variant={'outlined'}
                                        size={'small'}
                                        fullWidth
                                        LinkComponent={RouterLink}
                                        to={urlVehicleDeviceTimeline(projectSlug, vehicle, {
                                            startTime: [
                                                toTimestampMs(subSeconds(parseISO8601(event.occurredAt), 15)) / 1000,
                                            ],
                                            zoom: [10],
                                        })}
                                    >
                                        {'View on Timeline'}
                                    </Button>
                                </Permission>
                            </>
                        ) : null}

                        <DriverEventVideoList
                            event={event}
                            primaryVideos={primaryVideos || []}
                            nearbyVideos={nearbyVideos || []}
                            isLoading={isLoading}
                        />

                        <DriverEventMap ping={event} geofence={geofence} />
                    </Stack>
                }
            />
            <Permission auth={{ subject: 'DriverEvent', action: 'view_activity_log' }}>
                <DriverEventActivityLog event={event} />
            </Permission>
            <DriverEventExportArchive event={event} />
        </>
    );
}
