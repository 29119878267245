import * as React from 'react';

import { VehicleDevice } from '../../../types/stein';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { GeofenceChip } from '../../AppChip';
import { define } from '../../../utils/undefined-utils';
// import { CalibrationStatusChip } from '../../Vehicle/CalibrationStatusChip';
import { BatteryStatusIndicator } from '../../Vehicle/BatteryStatusIndicator';
import { ObservantUpdateIndicator } from '../../Vehicle/ObservantUpdateIndicator';
import { IOSUpdateIndicator } from '../../Vehicle/IOSUpdateIndicator';
import { WifiStatusIndicator } from '../../Vehicle/WifiStatusIndicator';
import { AlertsMutedIndicator } from '../../Vehicle/AlertsMutedIndicator';
import { VehicleLastSeen } from '../../Vehicle/VehicleLastSeen';
import { debugDataGridColumn, processDataGridCollums } from '../../DataGrid';
import { isVehicleOnline } from '../../Vehicle/vehicle_utils';
import { useAppSelector } from '../../../hooks/hooks';
import { selectDebugEnabled, selectVehicleModelById, selectVehicleModelsByProjectSlug } from '../../../store/selectors';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { Stack } from '@mui/system';
import { AppIconCameraDisabled, AppIconDevice, AppIconMicUnmute } from '../../AppIcons';

import { TooltipOptional } from '../../material/TooltipOptional';
import { useAppTheme } from '../../../theme';
import { Box } from '@mui/material';

// TODO remove ignore Cannot test inside DataGrid, wait for: https://github.com/mui-org/material-ui-x/issues/1151
/* istanbul ignore file */

function NameCell({ value, row }: GridRenderCellParams): React.ReactElement {
    const vehicle = row as VehicleDevice;
    const name = value as VehicleDevice['name'];
    const appInstall = vehicle.appInstall;

    return (
        <TooltipOptional
            title={'Device Connected'}
            description={
                appInstall ? (
                    <Stack spacing={0.5} mt={0.5}>
                        {appInstall.label ? <div>{`Name: ${appInstall.label}`}</div> : null}
                        {appInstall.serialNumber ? <div>{`Serial: ${appInstall.serialNumber}`}</div> : null}
                    </Stack>
                ) : null
            }
        >
            <div>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <span>{name}</span>
                    {appInstall ? <AppIconDevice /> : null}
                </Stack>
            </div>
        </TooltipOptional>
    );
}

function ModelCell({ value }: GridRenderCellParams): React.ReactElement {
    const modelId = value as VehicleDevice['vehicleModelId'];
    const model = useAppSelector((s) => selectVehicleModelById(s, modelId));

    return model ? <>{model.name}</> : <></>;
}

// function RegionCell({ row }: GridRenderCellParams): React.ReactElement {
//     const vehicle = row as VehicleDevice;
//     const regionId = vehicle?.regionId;

//     // istanbul ignore next
//     if (!regionId) {
//         // istanbul ignore next
//         return <></>;
//     }
//     return <RegionChip regionId={regionId} size={'small'} tabIndex={-1} />;
// }

function GeofenceCell({ row }: GridRenderCellParams): React.ReactElement {
    const vehicle = row as VehicleDevice;

    return (
        <Stack spacing={0.5}>
            {vehicle?.geofenceIds?.map((g) => <GeofenceChip geofenceId={g} size={'small'} tabIndex={-1} key={g} />)}
        </Stack>
    );
}

function StatusCell({ row }: GridRenderCellParams): React.ReactElement {
    const vehicle = row as VehicleDevice;
    const isOnline = isVehicleOnline(vehicle);
    const isCameraDisabled = !vehicle.cameraEnabled;
    const theme = useAppTheme();
    const micDisabled = !vehicle.microphonePermissionGranted;

    return (
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
            {isOnline ? (
                <BatteryStatusIndicator
                    batteryState={define(vehicle.lastBatteryState)}
                    batteryLevel={define(vehicle.lastBatteryLevel)}
                    isOnline={isOnline}
                />
            ) : null}
            {isOnline ? <WifiStatusIndicator vehicle={vehicle} /> : null}
            <AlertsMutedIndicator vehicle={vehicle} />
            {isCameraDisabled ? (
                <TooltipOptional title={'Device Camera Disabled'} description={'This vehicle will not detect events'}>
                    <Box display="flex" justifyContent={'center'} sx={{ color: theme.palette.error.main }}>
                        <AppIconCameraDisabled />
                    </Box>
                </TooltipOptional>
            ) : null}
            {micDisabled ? (
                <TooltipOptional title={'Microphone Not Enabled'} description={'This vehicle will not record audio'}>
                    <Box display="flex" justifyContent={'center'} sx={{ color: theme.palette.error.main }}>
                        <AppIconMicUnmute />
                    </Box>
                </TooltipOptional>
            ) : null}
        </Stack>
    );
}

function LastSeenCell({ row }: GridRenderCellParams): React.ReactElement {
    const vehicle = row as VehicleDevice;

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', fontSize: '10px' }}>
            <VehicleLastSeen vehicle={vehicle} />
        </Box>
    );
}

// function CalibrationStatusCell({ row }: GridRenderCellParams): React.ReactElement {
//     const vehicle = row as VehicleDevice;
//     const calibrationStatus = vehicle.calibrationStatus;

//     return <CalibrationStatusChip isOnline={isVehicleOnline(vehicle)} status={calibrationStatus} />;
// }

function IOSVersionCell({ row }: GridRenderCellParams): React.ReactElement {
    const vehicle = row as VehicleDevice;

    return <IOSUpdateIndicator vehicle={vehicle} size={'24px'} />;
}

function UpdateNeededCell({ row }: GridRenderCellParams): React.ReactElement {
    const vehicle = row as VehicleDevice;

    return <ObservantUpdateIndicator vehicle={vehicle} />;
}

function SnapshotCell({ row }: GridRenderCellParams): React.ReactElement | null {
    const vehicle = row as VehicleDevice;
    const snapshot = vehicle?.lastAbsenceSnapshot;

    if (!snapshot?.imgSrc) {
        return null;
    }

    return <img src={snapshot.imgSrc} style={{ height: '100%' }} />;
}

export function useVehicleDeviceColumns(): GridColDef[] {
    const projectSlug = useActiveProjectSlug();
    const debug = useAppSelector(selectDebugEnabled);
    const vehicleModels = useAppSelector((s) => selectVehicleModelsByProjectSlug(s, projectSlug));

    return React.useMemo(
        () =>
            processDataGridCollums([
                // {
                //     field: 'regionId',
                //     headerName: 'Region',
                //     renderCell: RegionCell,
                //     minWidth: 150,
                //     width: 150,
                // },
                {
                    field: 'name',
                    headerName: 'Name',
                    renderCell: NameCell,
                    minWidth: 150,
                    width: 150,
                },
                vehicleModels.length
                    ? {
                          field: 'vehicleModelId',
                          headerName: 'Model',
                          renderCell: ModelCell,
                          minWidth: 150,
                          width: 150,
                      }
                    : null,
                // {
                //     field: 'calibrationStatus',
                //     headerName: 'Calibration',
                //     renderCell: CalibrationStatusCell,
                //     minWidth: 150,
                //     width: 150,
                // },
                debugDataGridColumn(
                    {
                        field: 'osVersion',
                        headerName: 'iOS',
                        renderCell: IOSVersionCell,
                        minWidth: 75,
                        width: 75,
                    },
                    debug,
                ),
                debugDataGridColumn(
                    {
                        field: 'bundleNeedsUpdate',
                        headerName: 'Update',
                        renderCell: UpdateNeededCell,
                        minWidth: 75,
                        width: 75,
                    },
                    debug,
                ),
                {
                    field: 'geofenceId',
                    headerName: 'Facility',
                    renderCell: GeofenceCell,
                    minWidth: 200,
                    width: 200,
                },
                {
                    field: 'wifiLastConnectedAt',
                    headerName: 'Status',
                    renderCell: StatusCell,
                    minWidth: 150,
                    width: 150,
                },
                {
                    field: 'ping',
                    headerName: `Last seen`,
                    renderCell: LastSeenCell,
                    minWidth: 100,
                    flex: 1,
                    headerAlign: 'left',
                },
                debugDataGridColumn(
                    {
                        field: 'lastDiagnosticSnapshot',
                        headerName: 'Snapshot',
                        renderCell: SnapshotCell,
                        minWidth: 150,
                        width: 150,
                        headerClassName: 'godmode',
                    },
                    debug,
                ),
            ]),
        [debug],
    );
}
