import * as React from 'react';
import { CircularProgress, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { AppIconComponent } from '../AppIcons';
import { useHasUnsavedChanges } from '../../hooks/use-has-unsaved-changes';

export type InputWithActionProps = {
    value: string;
    label: string;
    onAction: (s: string | null) => void;
    loading: boolean;
    Icon: AppIconComponent;
};

type UseInputActionRes = {
    handleKeyDown: (evt: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleValueChange: React.Dispatch<React.SetStateAction<string | null>>;
    displayValue: string;
    endAdornment: React.ReactElement | null;
};

export function useInputAction({
    value,
    loading,
    onAction,
    Icon,
}: Pick<InputWithActionProps, 'value' | 'onAction' | 'loading' | 'Icon'>): UseInputActionRes {
    const [stagedValue, setStagedValue] = React.useState<string>(value);
    const displayValue = stagedValue;
    const hasValueChanged = stagedValue !== value;
    useHasUnsavedChanges(hasValueChanged);

    React.useEffect(() => {
        setStagedValue(value);
    }, [value]);

    function handleActionClick(): void {
        // istanbul ignore next
        if (!loading) {
            onAction(stagedValue);
        }
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleActionClick();
        }
    }

    return {
        handleKeyDown,
        handleValueChange: setStagedValue,
        displayValue,
        endAdornment: hasValueChanged ? (
            <InputAdornment position="end">
                <Tooltip title={'ctrl + enter'}>
                    <IconButton color="primary" onClick={handleActionClick}>
                        {loading ? <CircularProgress size={'18px'} /> : <Icon size={'18px'} />}
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        ) : null,
    };
}
